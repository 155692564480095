import { FormattedMessage } from 'react-intl';
import { lazy, Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom';

const HomePage = lazy(() => import('../HomePage'));
const Login = lazy(() => import('../login'));
const RewardFarmer = lazy(() => import('../RewardFarmer'));
const SelectFarmer = lazy(() => import('../SelectFarmer'));
const Wallet = lazy(() => import('../Wallet/index'));
const ContactForm = lazy(() => import('../ContactForm'));
const PrivacyPolicy = lazy(() => import('../PrivacyPolicy'));
const GroupDetails = lazy(() => import('../GroupDetails'));
const GroupThankYou = lazy(() => import('../GroupThankYou'));
const Campaign = lazy(() => import('../Campaign'));
const Screen = lazy(() => import('../Screen'));

import { selectIsLoggedIn } from '../login/redux';
import { ThemeContext } from 'components/Theme/Context';
import { useAppSelector } from 'app/hooks';
import * as S from './styled';

export default function App() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const { theme } = useContext(ThemeContext);

  return (
    <Router>
      <div>
        <Suspense fallback={
          <S.Container theme={theme}>
            <S.Loader><FormattedMessage id='loading' /></S.Loader>
          </S.Container>
        }>
          <Switch>
            {isLoggedIn && (
              [
                <Route exact path="/wallet" key={2}>
                  <Wallet />
                </Route>
              ]
            )}
            <Route exact path="/screen">
              <Screen />
            </Route>
            <Route exact path="/StartCampaign">
              <Campaign />
            </Route>
            <Route exact path="/groupThankYou">
              <GroupThankYou />
            </Route>
            <Route exact path="/groupDetails">
              <GroupDetails />
            </Route>
            <Route exact path="/contactUs">
              <ContactForm />
            </Route>
            <Route exact path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route exact path="/rewarded">
              <RewardFarmer />
            </Route>
            <Route exact path="/reward">
              <SelectFarmer />
            </Route>            
            <Route exact path="/reward/:publicId">
              <SelectFarmer />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/wallet" key={2}>
              <Wallet />
            </Route>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
}
