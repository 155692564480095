import { createSlice } from '@reduxjs/toolkit';

import { Farmer, ThunkArgs, IResponse } from 'types';
import { getFlag } from 'utils';
import { RootState } from 'app/store';
import { selectCredentials, doLogout } from 'features/login/redux';
import { TRANS_STATUS } from 'appConstants';

export const hot = '../features/SelectFarmer/redux.ts';

const DEVELOPMENT_TIMEOUT = 60 * 10 ; // 10 MINUTES FOR DEVELOPMENT
const PRODUCTION_TIMEOUT = 60 * 3 ; // 3 MINUTES FOR PRODUCTION

export interface SFState {
	showModal: boolean | null;
	farmerBrowseIndex: number;
  allFarmers: Farmer[];
  farmers: Farmer[];
  farmer: Farmer | null;
  primary: Farmer | null;
  amount: number | null;
  transfer: any | null;
  error: string | null;
	proceed: boolean | null;
	maxpoll: number | null;
}

const initialState: SFState = {
  showModal: false,
  farmerBrowseIndex: 0,
  allFarmers: [],
  farmers: [],
  farmer: null,
  primary: null,
  amount: 0,
  transfer: null,
  error: null,
 	proceed: true,
 	maxpoll: 0,
};

export const counterSlice = createSlice({
  name: 'farmers',
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.showModal = action.payload;
    },
    farmerBrowseIndex: (state, action) => {
      state.farmerBrowseIndex = action.payload;
    },
    allFarmers: (state, action) => {
      state.allFarmers = action.payload;
    },
    farmers: (state, action) => {
      state.farmers = action.payload;
    },
    farmer: (state, action) => {
      state.farmer = action.payload;
    },
    primary: (state, action) => {
      state.primary = action.payload;
    },
    amount: (state, action) => {
      state.amount = action.payload;
    },
    error: (state, action) => {
      state.error = action.payload;
    },
 		transfer: (state, action) => {
      Object.assign(state, { transfer: action.payload });
    },
    description: (state, action) => {
      const index = state.farmers.findIndex(({ id }) => action.payload === id );
      state.farmers[index] = { ...state.farmers[index] };
    },
 		proceed: (state, action) => {
      state.proceed = action.payload;
    },
 		maxpoll: (state, action) => {
      state.maxpoll = action.payload;
    },
  },
});

export const {
  showModal,
  farmerBrowseIndex,
  allFarmers,
  farmers,
  farmer,
  amount,
  primary,
  transfer,
  error,
  proceed,
  maxpoll,
} = counterSlice.actions;

export const selectShowModal = (state: RootState) => state.farmers.showModal;

export const selectFarmerBrowseIndex = (state: RootState) => state.farmers.farmerBrowseIndex;

export const selectAllFarmers = (state: RootState) => state.farmers.allFarmers;

export const selectFarmers = (state: RootState) => state.farmers.farmers;

export const selectFarmer = (state: RootState) => state.farmers.farmer;

export const selectPrimary = (state: RootState) => state.farmers.primary;

export const selectAmount = (state: RootState) => state.farmers.amount;

export const selectTransfer = (state: RootState) => state.farmers.transfer;

export const selectError = (state: RootState) => state.farmers.error;

export const canProceed = (state: RootState) => state.farmers.proceed;

export const selectMaxPoll = (state: RootState) => state.farmers.maxpoll;

export const giveAmount = (amt: number, userAuth:any=false ) => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  dispatch(amount(amt));
 	dispatch(proceed(true));
 	dispatch(maxpoll(0));
  const state = getState();
  const farmer = selectFarmer(state);
  let result: IResponse;
  if (userAuth){
  	result = await dispatch(callEndpoint({ api: 'api/Wallet/V1.0/Transfer/PreAuth/Farmer', method: 'POST', body: {
	  toAccountId: farmer?.publicWalletId, quantity: amt , preAuthId:userAuth } }));
  } else {
	  const { token } = selectCredentials(state);
  	result = await dispatch(callEndpoint({ api: 'api/Wallet/V1.0/Transfer/Farmer', method: 'POST', body: {
	  toAccountId: farmer?.publicWalletId, quantity: amt }, token }));
  }
  if (result.status ==='success') {
    dispatch(transfer({ ...result.data, status: TRANS_STATUS.processing }));
    setTimeout(() => dispatch(pollTransfer()), 1000);
  } else {
    dispatch(error(result.errors.join(', ')));
  }
  return result;
};

export const pollTransfer = () => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<void> => {
  const state = getState();
  const trans = selectTransfer(state);
 	const proceed = canProceed(state);
 	const maxPolls = selectMaxPoll(state);
  const { token } = selectCredentials(state);

 	if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    	if(maxPolls && maxPolls >= DEVELOPMENT_TIMEOUT ) {
  			alert(maxPolls);
 				dispatch(error(null));
 				dispatch(transfer(null));
 			  dispatch(maxpoll(0));
 				dispatch(transfer({ ...trans, status: TRANS_STATUS.transactionTimedOut }));
 		 		return;
 			}
 	} else {
    if(maxPolls && maxPolls >= PRODUCTION_TIMEOUT ) {
 			dispatch(error(null));
 			dispatch(transfer(null));
 			dispatch(maxpoll(0));
 			dispatch(transfer({ ...trans, status: TRANS_STATUS.transactionTimedOut }));
 		 	return;
 		}
 	}
  if(!proceed) {
 		dispatch(error(null));
 		dispatch(transfer(null));
 		dispatch(maxpoll(0));
 		return;
 	}
  const result: IResponse = await dispatch(callEndpoint({ api: `api/Wallet/V1.0/TransferStatus/${trans.queryId}`, method: 'GET', token }));
  if (proceed && [TRANS_STATUS.none, TRANS_STATUS.queued, TRANS_STATUS.verifyingData, TRANS_STATUS.processing, TRANS_STATUS.processingBlockChain].find(status => status === result?.data?.status)) {
    setTimeout(()=> {
 			if(maxPolls) {
 			 	dispatch(maxpoll(maxPolls + 1));
 			}
  		dispatch(transfer({ ...trans, status: result?.data?.status }));
 			dispatch(pollTransfer());},
  	1000);
  } else {
    if (result.error) {
      dispatch(error(result.error));
      setTimeout(() => {
        dispatch(doLogout());
        dispatch(error(null));
        dispatch(transfer(null));
      }, 10000);
    } else {
      dispatch(transfer(result.data));
    }
  }
};

export const publicFarmer = (publicId: string | null) => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<void> => {
  if (publicId === null) return;
  const result =  await dispatch(callEndpoint({ api: `api/Farmer/v1.0/Farmer/${publicId}`, method: 'Get' }));
  if (result.status === 'success') {
    const flag: any = await getFlag(result.data);
    const f: Farmer = { ...result.data, flag };
    dispatch(farmer(f));
    const fs = selectFarmers(getState()).slice();
    const index = fs.findIndex(({ id }) => id === f.id);
    if (index < 0) {
      fs.unshift(f);
    } else {
      fs[index] = f;
    }
    dispatch(farmers(fs));
  }
};

export const primaryFarmer = () => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<void> => {
  const result =  await dispatch(callEndpoint({ api: 'api/Farmer/v1.0/PrimaryFarmer', method: 'Get' }));
  if (result.status === 'success') {
    const flag: any = await getFlag(result.data);
    const f: Farmer = { ...result.data, flag };
    dispatch(farmer(f));
    dispatch(primary(f));
    const fs = selectFarmers(getState()).slice();
    if (!fs.find(({ id }) => id === f.id)) {
      fs.unshift(f);
      dispatch(farmers(fs));
    }
  }
};

export const init = () => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<void> => {
  const result = await dispatch(callEndpoint({ api: 'api/Farmer/v1.0/FarmerList', method: 'Get' }));
  if (result.status === 'success') {
    const ps = result.data.map(async (f: Farmer) => {
      const farmer = { ...f };
      const flag = await getFlag(farmer);
      return { ...farmer, flag };
    });
    const rs = await Promise.all(ps);
    dispatch(farmers(rs));
    dispatch(allFarmers(rs));
  }
};

export const getGroup = (id) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<void> => {
  const result = await dispatch(callEndpoint({ api: 'api/Farmer/v1.0/Group/List/'+id, method: 'Get' }));
  if (result.status === 'success') {
    return result.data;
  }
  return undefined;
};

export const registerUser = ( email ) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<any> => {
  var campaign = new URL(window.location.href).searchParams.get('campaign');
  var campaignAuth = new URL(window.location.href).searchParams.get('auth');
  const result = await dispatch(callEndpoint({ api: 'api/Campaign/v1.0/RegisterUser', method: 'POST' ,
	  body:{ campaignAuth:campaignAuth, campaign:campaign, Email:email } }));
  return result;
};

export const confirmUser = ( validateKey ) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<any> => {
  const result = await dispatch(callEndpoint({ api: 'api/Campaign/v1.0/ConfirmUser', method: 'POST' ,
	  body:{ validateKey:validateKey } }));
  return result;
};

export default counterSlice.reducer;
