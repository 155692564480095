import { createSlice, current } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ThunkArgs, IResponse, Credentials, Profile } from 'types';

export const hot = '../features/login/redux.ts';

export interface LoginState {
  loggedIn: boolean;
  status: 'idle' | 'loading' | 'failed';
  error: any;
  response: any;
  credentials: Credentials;
  profile: Profile;
  balance: number | null;
}

const initialState: LoginState = {
  loggedIn: false,
  status: 'idle',
  error: '',
  response: {} as any,
  credentials: {} as Credentials,
  profile: {} as Profile,
  balance: null
};

export const counterSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      // state.loggedIn = true;
      state.credentials = action.payload;
    },
    failure: (state, action) => {
      state.loggedIn = false;
      state.error = action.payload;
    },
    status: (state, action) => {
      state.status = action.payload;
    },
    loggedIn: (state) => {
      state.loggedIn = true;
    },
    logout: (state) => {
      state.loggedIn = false;
      Object.assign(state, { credentials: {}, balance: null });
    },
    balance: (state, action) => {
      state.balance = action.payload;
    },
    error: (state, action) => {
      state.error = action.payload;
    },
    profile: (state, action) => {
      const profile = action.payload;
      Object.assign(state, { profile });
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = 'loading';
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = 'idle';
  //       state.value += action.payload;
  //     });
  // },
});

export const { login, failure, status, loggedIn, logout, profile, balance, error } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.login.value)`
export const selectIsLoggedIn = (state: RootState) => state.login.loggedIn;

export const selectProfile = (state: RootState) => state.login.profile;

export const selectStatus = (state: RootState) => state.login.status;

export const selectError = (state: RootState) => state.login.error;

export const selectCredentials = (state: RootState) => state.login.credentials;

export const selectBalance = (state: RootState) => state.login.balance;

export const getBalance = ( useThisBalance=false ) => async (
  dispatch: Function, getState: Function, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  if (useThisBalance){
  	dispatch(balance(useThisBalance));
  }
  const state = getState();
  const { token } = selectCredentials(state);
  const result: IResponse = await dispatch(callEndpoint({ api: 'api/Wallet/V1.0/Balance', method: 'GET', token }));
  if (!useThisBalance && result.status === 'success') {
    const bal = result.data.items[0].quantity;
    dispatch(balance(bal));
  }
  return result;
};

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const asyncLogin = (username: string, password: string) => async (
  dispatch: Function, _: void, { callEndpoint }: ThunkArgs
): Promise<IResponse> => {
  const response: IResponse = await dispatch(callEndpoint({ api: 'api/Authenticate/V1.0/PopupLogon', method: 'POST', body: { username, password } }));
  if (response.status !== 'error') {
    const expires = +(new Date()) + (response.data.expiresIn * 1000);
    const creds: Credentials = { ...response.data, expires };
    dispatch(login(creds));
    sessionStorage.setItem('auth', JSON.stringify(creds));
    dispatch(expireCreds());
  }
  else dispatch(failure(response.errors));
  return response;
};

export const expireCreds = () => async (
  dispatch: Function, getState: Function
): Promise<void> => {
  const creds = selectCredentials(getState());
  if (+(new Date()) > creds.expires) dispatch(doLogout());
  else setTimeout(() => dispatch(expireCreds()), 1000);
};

export const doLogout = () => async (
  dispatch: Function, _: void
): Promise<void> => {
  dispatch(logout());
  sessionStorage.setItem('auth', '');
};

export const init = () => async (
  dispatch: Function, _: void
): Promise<void> => {
  const data: string | null = sessionStorage.getItem('auth');
  if (data) {
    dispatch(login(JSON.parse(data)));
    // dispatch(loggedIn());
    dispatch(expireCreds());
  }
  dispatch(profile({ balance: 8600 }));
};

export default counterSlice.reducer;
