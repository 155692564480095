import { Farmer } from 'types';

export async function getFlag(farmer: Farmer) {
  if (!farmer?.country2C) return '';
  const flag = await require(`assets/flags/${farmer?.country2C?.toLowerCase()}.png`);
  return flag.default;
}

export const isValidEmail = (val: string) => {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEmail.test(val);
};
