import EtFlag from 'assets/flagge-aethiopien.png';
import AuFlag from 'assets/au_flag.png';

export const flags = {
  Australia: AuFlag,
  Etheopia: EtFlag
};

export enum TRANS_STATUS {
  none = 0, //keep on polling
  queued = 1, //keep on polling
  processing = 2, //keep on polling
  success = 3, //All ok, finished
  failedNotEnoughMoney = 4, //finish
  failedTooMuchMoney = 5, //finish
	failedUnknown = 6, //finish,
	processingBlockChain = 7, //keep on polling, Ready to be processed by block chain.
	accountInUse = 8, //finish
	unknownFrom =9, //finish
	unknownTo = 10, //finish,
  verifyingData = 11, //keep on polling,
	transactionTimedOut = 12,
}
